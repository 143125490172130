<template>
  <transition
    name="scroll-x-transition"
    mode="out-in"
    appear
  >
    <div
      v-if="isLoading"
      class="d-flex justify-center"
    >
      <v-progress-circular
        :size="50"
        indeterminate
        color="primary"
      >
      </v-progress-circular>
    </div>
    <div v-else>
      <TourGuideCards v-if="$store.state.tourGuideShow.value"></TourGuideCards>
      <router-view></router-view>
    </div>
  </transition>
</template>

<script>
import TourGuideCards from '@/components/TourGuide/TourGuideCards.vue'
import { i18n } from '@/plugins/i18n/index'
import { useRouter } from '@core/utils'
import { defineComponent, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default defineComponent({
  components: { TourGuideCards },
  setup() {
    const { route } = useRouter()
    const eventId = route.value.params.id
    const isLoading = ref(true)
    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorManageEvents'))

    if (!store.state.eventData?.id || eventId !== store.state.eventData.id) {
      store
        .dispatch('fetchEvent', { id: eventId })
        .catch(() => showToast())
        .finally(() => {
          isLoading.value = false
        })
    } else {
      isLoading.value = false
    }

    return {
      eventId,
      isLoading,
    }
  },
})
</script>
