<template>
  <div id="tour-guide-cards">
    <v-dialog v-model="$store.state.tourGuideShow.value" fullscreen hide-overlay persistent scrollable>
      <v-container
        fluid
        class="d-flex justify-center dialog-container"
        :style="$vuetify.breakpoint.width > 300 ? 'background: #fafafa;' : ''"
      >
        <div v-if="loading">
          <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
        </div>
        <div v-else class="d-flex justify-center card-container">
          <v-card
            flat
            color="veryLightGreen"
            class="centered-card pb-0"
            :style="`height: 100%; width: 100%; ${$vuetify.breakpoint.xsOnly ? '' : 'max-width: 390px;'} ${
              $vuetify.breakpoint.smAndDown ? 'height: 100%;' : 'margin-top: 20px;'
            }`"
          >
            <div class="justify-center align-center text-center fixed sticky-story">
              <v-btn
                v-if="$store.state.tourGuideShow.type !== 'newEvent'"
                color="transparent"
                text
                class="pa-0 ma-0"
                @click="closeTourGuide"
              >
                <div class="black--text">{{ $t('Close') }}</div>
              </v-btn>
              <div v-else class="pt-4"></div>
              <ProgressBar
                v-if="steps[currentStep]"
                :current-step-name="steps[currentStep].name"
                :current-step="currentStep"
                :steps-count="steps.length"
                :move-to-next-step="moveToNextStep"
                class="pb-5"
              ></ProgressBar>
            </div>
            <arrows
              :current-step="currentStep"
              :steps-count="steps.length"
              :move-to-next-step="moveToNextStep"
              :move-to-previous-step="moveToPreviousStep"
              :currentStepName="steps[currentStep].name"
            >

            </arrows>
            <div ref="tourGuideTop">
              <div class="card-content mt-8">
                <div v-for="(step, index) in steps" :key="index" v-show="currentStep === index">
                  <component
                    :is="step.component"
                    :step-index="index"
                    :current-step="currentStep"
                    :steps-count="steps.length"
                    :move-to-next-step="moveToNextStep"
                    :move-to-previous-step="moveToPreviousStep"
                    :selected-package.sync="selectedPackage"
                  ></component>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </v-container>
    </v-dialog>
  </div>
</template>


<script>
import { getFullImageUrl } from '@/@core/utils/ImageFromApi'
import '@/assets/tour-guide-animations.css'
import Arrows from '@/components/TourGuide/Arrows.vue'
import ProgressBar from '@/components/TourGuide/ProgressBar.vue'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import Vue from 'vue'
import { stepComponents, stepImages } from './stepMappings.js'

export default {
  components: { ProgressBar, Arrows },
  setup() {
    const loading = ref(true)
    const selectedPackage = ref(null)
    const steps = ref([])
    const currentStep = ref(0)
    const tourGuideTop = ref(null)

    const scrollToTop = () => {
      if (tourGuideTop.value) {
        tourGuideTop.value.scrollIntoView({ behavior: 'smooth' })
      }
    }

    const initializeSteps = async () => {
      steps.value = []
      steps.value = [
        { name: 'WelcomePagePreview', component: null },
        { name: 'InvitationPagePreview', component: null },
      ]

      if (store.state.eventData.progressStatus === 0) {
        steps.value.push({ name: 'PackageSelection', component: null })
      }

      steps.value.push(
        { name: 'MessagesPreview', component: null },
        { name: 'TimingPreview', component: null },
        { name: 'UploadGuestsPreview', component: null },
      )

      if (store.state.eventData.includeCallsPreference) {
        steps.value.splice(
          steps.value.findIndex(step => step.name === 'TimingPreview'),
          0,
          { name: 'CallsPreview', component: null },
        )
      }

      const loadComponents = steps.value.map(step =>
        stepComponents[step.name]().then(module => {
          step.component = module.default
        }),
      )

      const imagePromises = steps.value.flatMap(step => {
        return (stepImages[step.name] || []).map(src => Vue.prototype.$preloadImage(src))
      })

      if (store.state.eventData.image) {
        imagePromises.push(Vue.prototype.$preloadImage(getFullImageUrl(store.state.eventData.image)))
      }

      await Promise.all([...loadComponents, ...imagePromises])
      loading.value = false
    }

    const moveToNextStep = () => {
      if (!store.state.activeCall) {
        if (
          (currentStep.value < steps.value.length - 1 && steps.value[currentStep.value].name !== 'Package') ||
          selectedPackage.value
        ) {
          scrollToTop()
          currentStep.value += 1
        }
      }
    }

    const moveToPreviousStep = () => {
      if (currentStep.value > 0) {
        scrollToTop()
        currentStep.value -= 1
      }
    }

    const closeTourGuide = () => {
      currentStep.value = 0
      store.commit('setTourGuide', { value: false, type: '' })
    }

    watch(
      () => store.state.eventData,
      () => {
        initializeSteps()
      },
      { immediate: true },
    )

    return {
      currentStep,
      steps,
      moveToNextStep,
      moveToPreviousStep,
      selectedPackage,
      closeTourGuide,
      loading,
      tourGuideTop,
    }
  },
}
</script>

<style scoped>
.centered-card {
  width: 340px;
  display: flex;
  flex-direction: column;
}

.card-content {
  margin-top: 0;
  padding-top: 0;
}

.v-btn {
  margin-bottom: 10px;
}

.progress-bar {
  margin-bottom: 0;
}

.sticky-story {
  width: inherit;
  background: #f5f5f5;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: inherit;
  color: white;
  margin: 0 auto;
}

.dialog-container {
  padding: 0px;
  min-height: 100vh;
  position: absolute;
  overflow: hidden;
}

.card-container {
  width: 100%;
  overflow-y: scroll;
}
</style>
